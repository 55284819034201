// tslint:disable max-file-line-count max-line-length
// file that contains svg paths so be used only with Icon component
import React from 'react';

export const ArrowBack = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={width}
        height={height} viewBox="0 0 6 11">
        <path
            id="Icon-arrow-backward"
            data-name="Icon arrow-backward"
            d="M1.809,5.5,5.78,9.661a.81.81,0,0,1,0,1.11.732.732,0,0,1-1.062,0L.218,6.059A.812.812,0,0,1,.2,4.975L4.714.229a.73.73,0,0,1,1.062,0,.81.81,0,0,1,0,1.11Z"
            fill="#424242"
        />
    </svg>
);

export const ArrowForward = (
  width: number | undefined,
  height: number | undefined,
  className: string | undefined
) => (
  <svg xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height} viewBox="0 0 6 11">
      <path
          id="Icon-arrow-forward"
          data-name="Icon arrow-forward"
          // tslint:disable-next-line: max-line-length
          d="M4.191,5.5.22,9.661a.81.81,0,0,0,0,1.11.732.732,0,0,0,1.062,0l4.5-4.713A.812.812,0,0,0,5.8,4.975L1.286.229a.73.73,0,0,0-1.062,0,.81.81,0,0,0,0,1.11Z"
          fill="#424242"
      />
  </svg>
);

export const Dots = (
  width: number | undefined,
  height: number | undefined,
  className: string | undefined
) => (
  <svg xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height} viewBox="0 -192 512 512">
      <path
          d="m320 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
      />
      <path
          d="m128 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
      />
      <path
          d="m512 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
      />
  </svg>
);

export const Arrow = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 12 17"
        preserveAspectRatio="xMidYMid meet"
    >
        <g data-name="Group 9322">
            <g fill="currentColor" data-name="Group 7127">
                <path d="M6.029 0L12 5.277l-1.684 1.489-4.287-3.778-4.345 3.789L0 5.277z" />
                <path d="M7.25 17h-2.5V2.278h2.5z" data-name="Rectangle 9152" />
            </g>
        </g>
    </svg>
);

export const Exclamation = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={width}
        height={height}
        data-name="Group 16554"
        viewBox="0 0 2.789 13.479"
        preserveAspectRatio="xMidYMid meet"
    >
        <defs />
        <g data-name="Group 4881">
            <path
                fill="#fff"
                d="M0 1.395h2.784l-.965 7.52a.438.438 0 01-.86 0z"
                data-name="Rectangle 4770"
            />
        </g>
        <g data-name="Group 4882">
            <circle
                cx="1.394"
                cy="1.394"
                r="1.394"
                fill="#fff"
                data-name="Ellipse 428"
                transform="translate(0 10.69)"
            />
        </g>
        <g data-name="Group 4882">
            <circle cx="1.394" cy="1.394" r="1.394" fill="#fff" data-name="Ellipse 428" />
        </g>
    </svg>
);

export const Bell = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="6 6 14 14"
        preserveAspectRatio="xMidYMid meet"
    >
        <g data-name="Group 6580" transform="translate(-1093 -328)">
            <g data-name="Group 11">
                <g data-name="Group 10">
                    <g data-name="Group 8">
                        <path
                            fill="currentColor"
                            d="M1103.318 346.437a3.1 3.1 0 0 0 2.678 1.555 3.1 3.1 0 0 0 2.678-1.555z"
                            data-name="Path 15"
                        />
                    </g>
                    <g data-name="Group 9">
                        <path
                            fill="currentColor"
                            d="M1112.991 345.659h-13.99v-2.115l.532-.175a2.634 2.634 0 0 0
                            1.8-2.5v-2.206a4.669 4.669 0 0 1 4.663-4.663 4.67 4.67 0 0 1 4.664
                            4.663v2.206a2.631 2.631 0 0 0 1.8 2.5l.531.175zm-11.632-1.554h9.274a4.183
                            4.183 0 0 1-1.527-3.236v-2.206a3.114 3.114 0 0 0-3.109-3.109 3.113 3.113
                            0 0 0-3.109 3.109v2.206a4.184 4.184 0 0 1-1.53 3.236z"
                            data-name="Path 16"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const Cog = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="5 5 16 16"
        preserveAspectRatio="xMidYMid meet"
    >
        <g data-name="Group 6581">
            <g data-name="Group 5">
                <g data-name="Group 4">
                    <path
                        fill="currentColor"
                        d="M21 14.604v-3.2h-1.81a6.384 6.384 0 0 0-.9-2l1.215-1.213-1.7-1.7-1.214 1.215a6.373 6.373
                        0 0 0-2-.9v-1.81h-3.2v1.81a6.359 6.359 0 0 0-1.994.9L8.182 6.491l-1.7 1.7 1.214 1.213a6.384 6.384 0 0 0-.9
                        2h-1.81v3.2h1.81a6.36 6.36 0 0 0 .9 1.994l-1.214 1.214 1.7 1.7 1.215-1.215a6.358 6.358 0 0 0
                        1.994.9v1.81h3.2v-1.81a6.372 6.372 0 0 0 2-.9l1.214 1.215 1.7-1.7-1.215-1.214a6.36 6.36 0 0 0 .9-1.994zm-8
                        3.2a4.8 4.8 0 0 1-4.8-4.8 4.8 4.8 0 0 1 4.8-4.8 4.8 4.8 0 0 1 4.8 4.8 4.8 4.8 0 0 1-4.8 4.8z"
                        data-name="Path 13"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const Sun = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
    >
        <path
            id="Path_18"
            data-name="Path 18"
            d="M-2184.317-3083.986v-1.549a.6.6,0,0,0-.6-.6.6.6,0,0,0-.6.6v1.549a5,5,0,0,1,.6-.042A5.026,5.026,0,0,1-2184.317-3083.986Z"
            transform="translate(2192.922 3086.138)"
            fill="currentColor"
        />
        <path
            id="Path_19"
            data-name="Path 19"
            d="M-2185.525-3078.847v1.551a.6.6,0,0,0,.6.6.6.6,0,0,0,.6-.6v-1.551a4.741,4.741,0,0,1-.6.043A4.718,4.718,0,0,
            1-2185.525-3078.847Z"
            transform="translate(2192.922 3092.693)"
            fill="currentColor"
        />
        <path
            id="Path_20"
            data-name="Path 20"
            d="M-2180.623-3082.2h-1.55a5.007,5.007,0,0,1,.042.6,5.039,5.039,0,0,1-.042.6h1.55a.6.6,0,0,0,.6-.6A.6.6,0,0,0-2180.623-3082.2Z"
            transform="translate(2196.021 3089.594)"
            fill="currentColor"
        />
        <path
            id="Path_21"
            data-name="Path 21"
            d="M-2187.312-3082.2h-1.55a.6.6,0,0,0-.6.6.6.6,0,0,0,.6.6h1.55a4.941,4.941,0,0,1-.042-.6A4.909,4.909,0,0,1-2187.312-3082.2Z"
            transform="translate(2189.465 3089.594)"
            fill="currentColor"
        />
        <path
            id="Path_22"
            data-name="Path 22"
            d="M-2187.036-3079.985l-1.1,1.1a.6.6,0,0,0,0,.854.6.6,0,0,0,.426.177.6.6,0,0,0,.426-.177l1.1-1.1A4.779,4.779,0,0,
            1-2187.036-3079.985Z"
            transform="translate(2190.166 3092.001)"
            fill="currentColor"
        />
        <path
            id="Path_23"
            data-name="Path 23"
            d="M-2182.458-3082.857l1.1-1.1a.6.6,0,0,0,0-.852.6.6,0,0,0-.854,0l-1.1,1.1A4.812,4.812,0,0,1-2182.458-3082.857Z"
            transform="translate(2195.329 3086.838)"
            fill="currentColor"
        />
        <path
            id="Path_24"
            data-name="Path 24"
            d="M-2186.184-3083.709l-1.1-1.1a.6.6,0,0,0-.852,0,.6.6,0,0,0,0,.852l1.1,1.1A4.853,4.853,0,0,1-2186.184-3083.709Z"
            transform="translate(2190.166 3086.838)"
            fill="currentColor"
        />
        <path
            id="Path_25"
            data-name="Path 25"
            d="M-2183.312-3079.131l1.1,1.1a.609.609,0,0,0,.427.177.6.6,0,0,0,.426-.177.6.6,0,0,0,0-.854l-1.1-1.1A4.739,4.739,0,0,
            1-2183.312-3079.131Z"
            transform="translate(2195.329 3092.001)"
            fill="currentColor"
        />
        <path
            id="Path_26"
            data-name="Path 26"
            d="M-2177.266-3079.919a5.237,5.237,0,0,0-1-2.4,5.358,5.358,0,0,0-1.066-1.068,5.275,5.275,0,0,0-2.4-1,5.523,5.523,0,0,
            0-.757-.06,5.494,5.494,0,0,0-.755.06,5.267,5.267,0,0,0-2.4,1,5.389,5.389,0,0,0-1.068,1.068,5.258,5.258,0,0,0-1,2.4,
            5.5,5.5,0,0,0-.061.754,5.519,5.519,0,0,0,.061.756,5.265,5.265,0,0,0,1,2.4,5.353,5.353,0,0,0,1.068,1.066,5.246,5.246,
            0,0,0,2.4,1,5.2,5.2,0,0,0,.755.061,5.231,5.231,0,0,0,.757-.061,5.254,5.254,0,0,0,2.4-1,5.323,5.323,0,0,0,1.066-1.066,
            5.244,5.244,0,0,0,1-2.4,5.193,5.193,0,0,0,.061-.756A5.177,5.177,0,0,0-2177.266-3079.919Z"
            transform="translate(2190.491 3087.164)"
            fill="currentColor"
        />
    </svg>
);
export const Moon = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 10 16"
    >
        <path
            id="Path_3206"
            data-name="Path 3206"
            d="M-2209.2-3069.4a7.439,7.439,0,0,0,2.338-.226.561.561,0,0,0,.15-1.012,8.065,8.065,0,0,1-3.571-6.748,8.059,8.059,
            0,0,1,3.576-6.748.558.558,0,0,0-.117-1,7.4,7.4,0,0,0-1.918-.252,7.866,7.866,0,0,0-7.7,8.172A7.956,7.956,0,0,0-2209.2-3069.4Z"
            transform="translate(2216.448 3085.388)"
            fill="currentColor"
        />
    </svg>
);

export const Radar = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 27.5 26.378"
    >
        <g data-name="Group 6595" transform="translate(7015.7 -9974.7)">
            <g data-name="Group 6583">
                <circle
                    cx="13"
                    cy="13"
                    r="13"
                    fill="#303030"
                    data-name="Ellipse 185"
                    transform="translate(-7015 9975)"
                />
            </g>
            <g
                fill="none"
                stroke="#7ac943"
                strokeWidth="2"
                data-name="Ellipse 760"
                transform="translate(-7012 9978)"
            >
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9" />
            </g>
            <g
                fill="none"
                stroke="#7ac943"
                strokeWidth="3"
                data-name="Ellipse 762"
                transform="translate(-7007 9983)"
            >
                <circle cx="5" cy="5" r="5" stroke="none" />
                <circle cx="5" cy="5" r="3.5" />
            </g>
            <rect
                width="6"
                height="15"
                fill="#303030"
                data-name="Rectangle 8555"
                rx="3"
                transform="translate(-7005 9976)"
            />
            <g fill="#303030" data-name="Group 6584">
                <path
                    d="M-7002.999 9987.924V9976.7a11.364 11.364 0 0 1 7.978 3.245l-7.977 7.977z"
                    data-name="Subtraction 119"
                />
                <path
                    d="M-7003.05927 9987.80014l7.93657 7.93656a11.364 11.364 0 0 1-7.93586 3.34674v-11.28118z"
                    data-name="Subtraction 125"
                    opacity=".5"
                />
                <path
                    d="M-7002.99986 9987.93727l7.93656-7.93657a11.364 11.364 0 0 1 3.34674 7.93586h-11.28118z"
                    data-name="Subtraction 121"
                />
                <path
                    d="M-7003.025 9988v11.224a11.364 11.364 0 0 1-7.978-3.245l7.977-7.977z"
                    data-name="Subtraction 127"
                    opacity=".397"
                />
                <path
                    d="M-7003 9987.8h11.224a11.364 11.364 0 0 1-3.245 7.978l-7.977-7.977z"
                    data-name="Subtraction 123"
                    opacity=".749"
                />
                <path
                    d="M-7002.92214 9987.94073l-7.93656 7.93657a11.364 11.364 0 0 1-3.34674-7.93586h11.28118z"
                    data-name="Subtraction 129"
                    opacity=".198"
                />
            </g>
            <rect
                width="2"
                height="13"
                fill="#7ac943"
                data-name="Rectangle 8568"
                rx="1"
                transform="translate(-7003 9976)"
            />
        </g>
    </svg>
);

export const DropdownUp = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 17 17"
    >
        <g data-name="Icon ionic-ios-arrow-dropdown" fill="currentColor">
            <path
                data-name="Path 4490"
                d="M13.072 10.241a.724.724 0 000-1.072L9.035 5.422a.857.857 0 00-1.122-.024L3.935 9.09a.722.722 0 000 1.072.857.857 0 001.152 0L8.5 7.029l3.421 3.216a.857.857 0 001.151-.004z"
            />
            <path
                data-name="Path 4491"
                d="M17 8.5A8.5 8.5 0 108.5 17 8.5 8.5 0 0017 8.5zM3.416 13.584A7.19 7.19 0 1113.584 3.416 7.19 7.19 0 113.416 13.584z"
            />
        </g>
    </svg>
);

export const DropdownDown = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 17 17"
    >
        <g
            id="Icon_ionic-ios-arrow-dropdown"
            transform="rotate(180 10.188 10.188)"
            fill="currentColor"
        >
            <path
                id="Path_4490"
                d="M16.4 13.6c.3-.3.3-.7 0-1l-4-3.7c-.3-.3-.8-.3-1.1 0l-4 3.7c-.3.3-.3.7-.1 1l.1.1c.3.3.8.3 1.2 0l3.4-3.1 3.4 3.2c.3.1.8.1 1.1-.2z"
            />
            <path
                id="Path_4491"
                d="M20.4 11.9c0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5 3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5zM6.8 17C4 14.2 4 9.6 6.8 6.8S14.2 4 17 6.8s2.8 7.4 0 10.2-7.4 2.8-10.2 0z"
            />
        </g>
    </svg>
);

export const Medulla = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 214 47"
    >
        <g id="Group_14543" data-name="Group 14543" transform="translate(-9416 -16277.139)">
            <g id="Group_8661" data-name="Group 8661" transform="translate(9470 16291.142)">
                <path
                    id="Path_3672"
                    data-name="Path 3672"
                    d="M4669.758-259.661h7.29l2.95,10.994h.053L4683-259.661h7.346v19.651h-5.067v-12.313h-.055l-3.188,12.313h-4.1l-3.191-12.313h-.053v12.313h-4.933Z"
                    transform="translate(-4669.758 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3673"
                    data-name="Path 3673"
                    d="M4692.272-259.661H4707.2v4.672h-9.329v2.666h7.8v4.315h-7.8v3.079h9.6v4.919h-15.2Z"
                    transform="translate(-4663.954 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3674"
                    data-name="Path 3674"
                    d="M4710.161-259.661h8.847c5.521,0,9.354,3.628,9.354,9.867,0,6.266-3.645,9.784-9.6,9.784h-8.605Zm8.147,14.9c2.574,0,4.371-1.705,4.371-5.029,0-3.244-1.689-5.112-4.371-5.112h-2.545v10.142Z"
                    transform="translate(-4659.347 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3675"
                    data-name="Path 3675"
                    d="M4729.966-247.518v-12.143h5.6v12.472a2.681,2.681,0,0,0,3.03,2.939,2.727,2.727,0,0,0,2.867-3.049v-12.362h5.6v12.143c0,4.834-2.816,7.857-8.552,7.857C4732.753-239.661,4729.966-242.6,4729.966-247.518Z"
                    transform="translate(-4654.24 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3676"
                    data-name="Path 3676"
                    d="M4749.622-259.661h5.6v14.732h8.2v4.919h-13.805Z"
                    transform="translate(-4649.167 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3677"
                    data-name="Path 3677"
                    d="M4765.634-259.661h5.6v14.732h8.2v4.919h-13.8Z"
                    transform="translate(-4645.036 259.661)"
                    fill="#e7e7e7"
                />
                <path
                    id="Path_3678"
                    data-name="Path 3678"
                    d="M4788.245-259.661h5.708l7.1,19.651h-5.951l-.938-2.886h-6.487l-.938,2.886h-5.576Zm.695,12.807h3.969L4791.7-250.7c-.3-.961-.725-2.528-.725-2.528h-.053s-.4,1.567-.725,2.528Z"
                    transform="translate(-4641.057 259.661)"
                    fill="#e7e7e7"
                />
            </g>
            <line
                id="Line_528"
                data-name="Line 528"
                y2="46"
                transform="translate(9454.861 16277.639)"
                fill="none"
                stroke="#e7e7e7"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1"
            />
            <g id="Group_10665" data-name="Group 10665" transform="translate(9416 16283.454)">
                <g id="Group_10664" data-name="Group 10664" transform="translate(0)">
                    <path
                        id="Path_3861"
                        data-name="Path 3861"
                        d="M-790.772-2587.784c-1.363,0-2.5,1.323-2.731,3.066-.052.386.321.74.88.847l.564.107a6.989,6.989,0,0,0,2.577,0l.564-.107c.559-.106.932-.461.88-.847C-788.273-2586.461-789.407-2587.784-790.772-2587.784Z"
                        transform="translate(806.594 2617.644)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3862"
                        data-name="Path 3862"
                        d="M-798.071-2587.784c-1.363,0-2.5,1.323-2.731,3.066-.053.386.321.74.879.847l.564.107a6.99,6.99,0,0,0,2.577,0l.564-.107c.559-.106.932-.461.879-.847C-795.573-2586.461-796.707-2587.784-798.071-2587.784Z"
                        transform="translate(806.248 2617.644)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3863"
                        data-name="Path 3863"
                        d="M-783.79-2600.984a6.356,6.356,0,0,1-1.655,1.363l-.016.01a5.768,5.768,0,0,1-.679.328,5.516,5.516,0,0,1-1.472.37,1.425,1.425,0,0,0-1.161.949,3.708,3.708,0,0,0,.393,2.821l.1.17c1.051,1.851-.462,5.123-1.265,6.4a3.934,3.934,0,0,1,1.109.862c2.4-.952,5.249-5.657,5.249-13.164a.129.129,0,0,0-.016-.049A.348.348,0,0,0-783.79-2600.984Z"
                        transform="translate(806.803 2617.23)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3864"
                        data-name="Path 3864"
                        d="M-799.263-2588.573c-.8-1.276-2.316-4.549-1.266-6.4l.1-.17a3.706,3.706,0,0,0,.394-2.821,1.425,1.425,0,0,0-1.162-.949,5.508,5.508,0,0,1-1.471-.37,5.745,5.745,0,0,1-.679-.328l-.015-.01a6.376,6.376,0,0,1-1.656-1.363.348.348,0,0,0-.588.061.129.129,0,0,0-.016.049c0,7.506,2.85,12.211,5.249,13.164A3.935,3.935,0,0,1-799.263-2588.573Z"
                        transform="translate(806.013 2617.23)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3865"
                        data-name="Path 3865"
                        d="M-792.151-2600.308l0,0,0-.006h-.013a7.03,7.03,0,0,1-1.5-1.345,5.785,5.785,0,0,1-.775-1.22,5.747,5.747,0,0,1-.775,1.22,7.044,7.044,0,0,1-1.493,1.345h-.013l0,.006,0,0h.006c.082.115,1.036,2.167,1.563,3.074.52.892.937.97,1.428,0,.469-.932,1.481-2.957,1.562-3.072Z"
                        transform="translate(806.435 2617.089)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3866"
                        data-name="Path 3866"
                        d="M-783.152-2610.225a8.033,8.033,0,0,0-.973-1.3,7.57,7.57,0,0,0-1.154-1.025,9.545,9.545,0,0,0,1.227-1.706,8.829,8.829,0,0,0,.8-1.922.3.3,0,0,0-.127-.339.309.309,0,0,0-.366.012,14.091,14.091,0,0,1-3.1,1.93,12.8,12.8,0,0,1-4.011.916,4.568,4.568,0,0,0-1.958.626,2.681,2.681,0,0,0-.743.612.516.516,0,0,1-.442.249.515.515,0,0,1-.44-.249,2.68,2.68,0,0,0-.743-.612,4.568,4.568,0,0,0-1.958-.626,12.8,12.8,0,0,1-4.011-.916,14.088,14.088,0,0,1-3.1-1.93.309.309,0,0,0-.366-.012.3.3,0,0,0-.127.339,8.784,8.784,0,0,0,.8,1.922,9.558,9.558,0,0,0,1.227,1.706,7.567,7.567,0,0,0-1.154,1.025,8.033,8.033,0,0,0-.973,1.3,7.831,7.831,0,0,0-1.112,4.814,5.918,5.918,0,0,0,2,3.965l0,0,0,0c.085.07.171.139.26.205s.179.129.273.189a5.358,5.358,0,0,0,4.589.521,7.376,7.376,0,0,0,4-3.28c.1-.161.187-.324.271-.488s.162-.331.234-.5c.043-.1.241-.15.332-.15s.29.051.333.15c.072.167.149.333.234.5s.174.327.271.488a7.377,7.377,0,0,0,4,3.28,5.358,5.358,0,0,0,4.589-.521c.094-.06.185-.124.274-.189s.175-.135.26-.205l0,0,0,0a5.635,5.635,0,0,0,1.732-2.667,6.75,6.75,0,0,0,.264-1.3A7.833,7.833,0,0,0-783.152-2610.225Zm-15.886,6.662a4.135,4.135,0,0,1-4.159-4.109,4.134,4.134,0,0,1,4.159-4.109,4.167,4.167,0,0,1,3.436,1.8,4.108,4.108,0,0,1,.712,2.313,4.106,4.106,0,0,1-.712,2.313A4.168,4.168,0,0,1-799.038-2603.563Zm5.927-4.109a4.108,4.108,0,0,1,.712-2.313,4.166,4.166,0,0,1,3.436-1.8,4.134,4.134,0,0,1,4.159,4.109,4.135,4.135,0,0,1-4.159,4.109,4.165,4.165,0,0,1-3.436-1.8A4.106,4.106,0,0,1-793.111-2607.672Z"
                        transform="translate(806.001 2616.573)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3867"
                        data-name="Path 3867"
                        d="M-789.6-2605.383a2.483,2.483,0,0,0,2.483-2.482,2.483,2.483,0,0,0-2.483-2.483,2.482,2.482,0,0,0-2.482,2.483A2.482,2.482,0,0,0-789.6-2605.383Zm-.4-3.586a1.166,1.166,0,0,1,.4-.082,1.186,1.186,0,0,1,1.186,1.186,1.185,1.185,0,0,1-1.186,1.185,1.184,1.184,0,0,1-1.185-1.185,1.149,1.149,0,0,1,.165-.568A1.164,1.164,0,0,1-790-2608.97Z"
                        transform="translate(806.656 2616.806)"
                        fill="#e7e7e7"
                    />
                    <path
                        id="Path_3868"
                        data-name="Path 3868"
                        d="M-799.255-2610.348a2.482,2.482,0,0,0-2.483,2.483,2.483,2.483,0,0,0,2.483,2.482,2.482,2.482,0,0,0,2.482-2.482A2.482,2.482,0,0,0-799.255-2610.348Zm-1.186,2.483a1.156,1.156,0,0,1,.2-.616.891.891,0,0,0,.407-.4,1.15,1.15,0,0,1,.581-.174,1.185,1.185,0,0,1,1.185,1.186,1.184,1.184,0,0,1-1.185,1.185A1.185,1.185,0,0,1-800.441-2607.865Z"
                        transform="translate(806.202 2616.806)"
                        fill="#e7e7e7"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const Reset = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 19 19"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            id="Path_2251"
            data-name="Path 2251"
            d="M-767,388.6h-9v-1.269l.342-.106a1.594,1.594,0,0,0,1.158-1.5V384.4a2.908,2.908,0,0,1,3-2.8,2.908,2.908,0,0,1,3,2.8v1.324a1.594,1.594,0,0,0,1.158,1.5l.342.106Z"
            transform="translate(781.089 -376.651)"
            fill="currentColor"
        />
        <path
            id="Path_2252"
            data-name="Path 2252"
            d="M-769.433,391.948c-.728,2.7-3.585,4.731-6.995,4.731a7.144,7.144,0,0,1-6.869-4.319l2.083-1.765H-786v4.056l1.458-1.235a8.91,8.91,0,0,0,8.115,4.615c4.293,0,7.869-2.628,8.622-6.083Z"
            transform="translate(786 -379.032)"
            fill="currentColor"
        />
        <path
            id="Path_2253"
            data-name="Path 2253"
            d="M-767.377,377.795a8.91,8.91,0,0,0-8.115-4.615c-4.293,0-7.869,2.628-8.622,6.083h1.628c.728-2.705,3.585-4.732,6.995-4.732a7.145,7.145,0,0,1,6.869,4.319l-2.083,1.764h4.786v-4.056Z"
            transform="translate(784.919 -373.18)"
            fill="currentColor"
        />
    </svg>
);

export const Watch = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 19 13"
        preserveAspectRatio="xMidYMid meet"
    >
        <g id="Group_8808" data-name="Group 8808" transform="translate(11649 -21258)">
            <path
                id="ic_visibility_24px"
                d="M10.5,4.5A10.215,10.215,0,0,0,1,11a10.192,10.192,0,0,0,19,0A10.215,10.215,0,0,0,10.5,4.5Zm0,10.833A4.333,4.333,0,1,1,14.818,11,4.327,4.327,0,0,1,10.5,15.333Zm0-6.933A2.6,2.6,0,1,0,13.091,11,2.592,2.592,0,0,0,10.5,8.4Z"
                transform="translate(-11650 21253.5)"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const Unwatch = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 19 17"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            id="ic_visibility_off_24px"
            data-name="ic visibility off 24px"
            d="M-693.5,378.579a4.4,4.4,0,0,1,4.32,4.474,4.472,4.472,0,0,1-.311,1.637l2.523,2.613a10.571,10.571,0,0,0,2.964-4.25,10.232,10.232,0,0,0-9.5-6.711,9.748,9.748,0,0,0-3.438.626l1.866,1.932A4.069,4.069,0,0,1-693.5,378.579Zm-8.64-2.442,1.97,2.04.4.412A10.527,10.527,0,0,0-703,383.053a10.106,10.106,0,0,0,13.242,5.978l.047-.02.363.376,2.532,2.613,1.1-1.137L-701.038,375Zm4.778,4.948,1.339,1.387a2.625,2.625,0,0,0-.069.582,2.639,2.639,0,0,0,2.593,2.684,2.37,2.37,0,0,0,.562-.072l1.339,1.387a4.224,4.224,0,0,1-5.763-1.971A4.587,4.587,0,0,1-697.358,381.084Zm3.724-.7,2.722,2.818.017-.143a2.639,2.639,0,0,0-2.592-2.684Z"
            transform="translate(703 -375)"
            fill="currentColor"
        />
    </svg>
);

export const Details = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 19"
        preserveAspectRatio="xMidYMid meet"
    >
        <g id="Group_8649" data-name="Group 8649" transform="translate(-96 -627.184)">
            <path
                id="Path_3662"
                data-name="Path 3662"
                d="M108.067,646.184l-5.056-13.3-2.319,6.257H96v-2.024h3.307l3.681-9.933,4.944,13.009,2.882-8.749,2.8,5.673H120v2.024h-7.618l-1.2-2.422Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const Search = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 15.316 15.317"
    >
        <g data-name="Group 4467">
            <g data-name="Group 4059">
                <g data-name="Group 6">
                    <path
                        fill="currentColor"
                        d="M15.316 14.219l-4.191-4.192a6.153 6.153 0 0 0 1.31-3.809 6.173 6.173 0 0
                        0-1.821-4.4 6.177 6.177 0 0 0-4.4-1.822 6.173 6.173 0 0 0-4.4 1.822 6.171 6.171
                        0 0 0-1.821 4.4 6.175 6.175 0 0 0 1.821 4.4 6.174 6.174 0 0 0 4.4 1.821 6.151 6.151
                        0 0 0 3.808-1.311l4.193 4.191zm-9.1-3.337a4.632 4.632 0 0 1-3.3-1.367 4.621 4.621 0 0
                        1-1.366-3.3 4.624 4.624 0 0 1 1.366-3.3 4.626 4.626 0 0 1 3.3-1.366 4.628 4.628 0 0 1
                        3.3 1.366 4.632 4.632 0 0 1 1.366 3.3 4.629 4.629 0 0 1-1.366 3.3 4.634 4.634 0 0 1-3.298 1.367z"
                        data-name="Path 14"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const Pdf = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 15 17"
    >
        <g id="Group_18545" data-name="Group 18545" transform="translate(-1394 -338)">
            <g id="Group_18307" data-name="Group 18307" transform="translate(-268 63)">
                <path
                    id="Icon_metro-file-pdf"
                    data-name="Icon metro-file-pdf"
                    d="M16.907,5.808a2.2,2.2,0,0,1,.469.721,2.137,2.137,0,0,1,.2.835V18.293a.866.866,0,0,1-.273.645.918.918,0,0,1-.664.266H3.508a.918.918,0,0,1-.664-.266.866.866,0,0,1-.273-.645V3.114a.866.866,0,0,1,.273-.645A.918.918,0,0,1,3.508,2.2h8.75a2.31,2.31,0,0,1,.859.19,2.282,2.282,0,0,1,.742.455ZM12.571,3.494V7.061h3.672a1.028,1.028,0,0,0-.215-.389L12.971,3.7a1.076,1.076,0,0,0-.4-.209Zm3.75,14.5V8.275H12.258a.918.918,0,0,1-.664-.266.866.866,0,0,1-.273-.645V3.418h-7.5V17.989h12.5ZM11.3,12.364a7.45,7.45,0,0,0,.82.531,9.972,9.972,0,0,1,1.143-.066q1.436,0,1.729.465a.448.448,0,0,1,.02.493.027.027,0,0,1-.01.019l-.02.019v.009q-.059.36-.693.36A4.136,4.136,0,0,1,13.166,14a7.242,7.242,0,0,1-1.27-.5,18.032,18.032,0,0,0-3.828.787q-1.494,2.485-2.363,2.485a.581.581,0,0,1-.273-.066L5.2,16.595q-.01-.009-.059-.047a.383.383,0,0,1-.059-.342,2.049,2.049,0,0,1,.547-.868,4.714,4.714,0,0,1,1.289-.915.144.144,0,0,1,.225.057.054.054,0,0,1,.02.038q.508-.806,1.045-1.869a14.237,14.237,0,0,0,1.016-2.485,7.474,7.474,0,0,1-.3-1.513,3.586,3.586,0,0,1,.063-1.21q.107-.379.41-.379h.215a.417.417,0,0,1,.342.142.74.74,0,0,1,.088.645A.2.2,0,0,1,10,7.924a.24.24,0,0,1,.01.076v.285a12.075,12.075,0,0,1-.137,1.821A4.783,4.783,0,0,0,11.3,12.364Zm-5.625,3.9a4.234,4.234,0,0,0,1.338-1.5,5.5,5.5,0,0,0-.854.8A3.147,3.147,0,0,0,5.676,16.263ZM9.563,7.535a2.742,2.742,0,0,0-.02,1.252q.01-.066.068-.417,0-.028.068-.408a.211.211,0,0,1,.039-.076.027.027,0,0,1-.01-.019.019.019,0,0,0,0-.014.019.019,0,0,1,0-.014A.537.537,0,0,0,9.573,7.5a.027.027,0,0,1-.01.019v.019ZM8.352,13.806a14.65,14.65,0,0,1,2.773-.768,1.473,1.473,0,0,1-.127-.09,1.737,1.737,0,0,1-.156-.128,5.034,5.034,0,0,1-1.24-1.67,12.481,12.481,0,0,1-.811,1.869q-.293.531-.439.787Zm6.309-.152a2.4,2.4,0,0,0-1.367-.228,3.787,3.787,0,0,0,1.211.266.974.974,0,0,0,.176-.009q0-.009-.02-.028Z"
                    transform="translate(1659.429 272.797)"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>
);

export const Excel = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16.722 19"
    >
        <path
            d="M18.552,6.233a2.46,2.46,0,0,1,.523.806,2.393,2.393,0,0,1,.218.933V20.186a.969.969,0,0,1-.3.721,1.022,1.022,0,0,1-.74.3H3.616a1.022,1.022,0,0,1-.74-.3.969.969,0,0,1-.3-.721V3.221a.969.969,0,0,1,.3-.721,1.022,1.022,0,0,1,.74-.3H13.37a2.57,2.57,0,0,1,.958.212,2.543,2.543,0,0,1,.827.509ZM13.719,3.645V7.632h4.093a1.15,1.15,0,0,0-.239-.435L14.165,3.879a1.2,1.2,0,0,0-.446-.233Zm4.18,16.2V8.989H13.37a1.022,1.022,0,0,1-.74-.3.969.969,0,0,1-.3-.721V3.561H3.964V19.846H17.9ZM7.241,17.365v1.124H10.3V17.365H9.484L10.6,15.658a1.513,1.513,0,0,0,.109-.175q.054-.1.082-.143t.038-.042h.022a.361.361,0,0,0,.054.106.59.59,0,0,0,.049.08q.027.037.065.085l.071.09,1.165,1.707h-.827v1.124H14.6V17.365h-.74l-2.09-2.895,2.123-2.99h.729V10.346H11.585v1.134h.806l-1.121,1.686q-.044.074-.109.175t-.1.143l-.022.032h-.022a.361.361,0,0,0-.054-.106,1.267,1.267,0,0,0-.185-.244L9.625,11.481h.827V10.346H7.3v1.134h.74l2.058,2.884-2.112,3h-.74Z"
            transform="translate(-2.571 -2.203)" fill="currentColor"
        />
    </svg>
);

export const Csv = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16.722 19"
    >
        <g transform="translate(0 0)">
            <path d="M18.552,6.233a2.46,2.46,0,0,1,.523.806,2.393,2.393,0,0,1,.218.933V20.186a.969.969,0,0,1-.3.721,1.022,1.022,0,0,1-.74.3H3.616a1.022,1.022,0,0,1-.74-.3.969.969,0,0,1-.3-.721V3.221a.969.969,0,0,1,.3-.721,1.022,1.022,0,0,1,.74-.3H13.37a2.57,2.57,0,0,1,.958.212,2.543,2.543,0,0,1,.827.509ZM13.719,3.645V7.632h4.093a1.15,1.15,0,0,0-.239-.435L14.165,3.879a1.2,1.2,0,0,0-.446-.233Zm4.18,16.2V8.989H13.37a1.022,1.022,0,0,1-.74-.3.969.969,0,0,1-.3-.721V3.561H3.964V19.846H17.9Z" transform="translate(-2.57 -2.204)" fill="currentColor"/>
            <path d="M7.346,19.067a.356.356,0,0,1-.356.356H6.634a.711.711,0,0,0-.711.711v1.423a.711.711,0,0,0,.711.711H6.99a.356.356,0,0,1,.356.356v.711a.356.356,0,0,1-.356.356H6.634A2.134,2.134,0,0,1,4.5,21.557V20.134A2.134,2.134,0,0,1,6.634,18H6.99a.356.356,0,0,1,.356.356Zm1.968,4.624H8.769a.356.356,0,0,1-.356-.356v-.711a.356.356,0,0,1,.356-.356h.546c.265,0,.463-.156.463-.294a.235.235,0,0,0-.094-.171l-.973-.835a1.65,1.65,0,0,1-.593-1.251A1.809,1.809,0,0,1,10,18h.545a.356.356,0,0,1,.356.356v.711a.356.356,0,0,1-.356.356H10c-.265,0-.463.156-.463.294a.235.235,0,0,0,.094.171l.973.835a1.65,1.65,0,0,1,.593,1.251A1.808,1.808,0,0,1,9.314,23.692Zm3.723-5.336v.925a4.847,4.847,0,0,0,.711,2.529,4.846,4.846,0,0,0,.711-2.529v-.925A.356.356,0,0,1,14.816,18h.711a.356.356,0,0,1,.356.356v.925a6.116,6.116,0,0,1-1.613,4.184.711.711,0,0,1-1.042,0,6.116,6.116,0,0,1-1.613-4.184v-.925A.356.356,0,0,1,11.97,18h.711A.356.356,0,0,1,13.037,18.356Z" transform="translate(-1.789 -9.214)" fill="currentColor"/>
        </g>
    </svg>
);

export const RatingStar = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 15"
    >
        <path d="M8,0l2,5.578,6,.151-4.764,3.6L12.944,15,8,11.646,3.056,15,4.764,9.328,0,5.729l6-.151Z" fill="currentColor"/>
    </svg>
);

export const FilterSortUp = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 27 17.17"
    >
        <g id="Group_18547" data-name="Group 18547" transform="translate(-152.002 -119)">
            <g
                id="Group_18441"
                data-name="Group 18441"
                transform="translate(161.998 136.072) rotate(180)"
            >
                <path
                    id="ic_check_box_24px"
                    data-name="ic check box 24px"
                    d="M4.976,6.771,0,1.489,1.4,0,4.976,3.789,8.6,0,10,1.5Z"
                    transform="translate(0 10.228)"
                    fill="currentColor"
                />
                <rect
                    id="Rectangle_18539"
                    data-name="Rectangle 18539"
                    width="2"
                    height="14.722"
                    transform="translate(4)"
                    fill="currentColor"
                />
            </g>
            <path
                id="Path_4745"
                data-name="Path 4745"
                d="M749.514,448.3a.9.9,0,0,1-.439-.113L746,446.48a.9.9,0,0,1-.465-.79v-5.278l-5.96-7.831a.9.9,0,0,1,.719-1.45h15.362a.9.9,0,0,1,.719,1.45l-5.96,7.831V447.4a.9.9,0,0,1-.9.9Zm-2.17-3.143,1.266.7v-5.754a.9.9,0,0,1,.185-.546l5.04-6.623H742.119l5.04,6.623a.9.9,0,0,1,.185.546Z"
                transform="translate(-577.561 -312.131)"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const FilterSortDown = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 27 17.17"
    >
        <g id="Group_18546" data-name="Group 18546" transform="translate(-111 -119)">
            <g id="Group_18440" data-name="Group 18440" transform="translate(111 119.073)">
                <path
                    id="ic_check_box_24px"
                    data-name="ic check box 24px"
                    d="M730.273,448.2l-4.976-5.282,1.4-1.488,3.576,3.788,3.621-3.789,1.4,1.5Z"
                    transform="translate(-725.297 -431.204)"
                    fill="currentColor"
                />
                <rect
                    id="Rectangle_18539"
                    data-name="Rectangle 18539"
                    width="2"
                    height="14.722"
                    transform="translate(4)"
                    fill="currentColor"
                />
            </g>
            <path
                id="Path_4744"
                data-name="Path 4744"
                d="M749.514,448.3a.9.9,0,0,1-.439-.113L746,446.48a.9.9,0,0,1-.465-.79v-5.278l-5.96-7.831a.9.9,0,0,1,.719-1.45h15.362a.9.9,0,0,1,.719,1.45l-5.96,7.831V447.4a.9.9,0,0,1-.9.9Zm-2.17-3.143,1.266.7v-5.754a.9.9,0,0,1,.185-.546l5.04-6.623H742.119l5.04,6.623a.9.9,0,0,1,.185.546Z"
                transform="translate(-618.562 -312.131)"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const Delete = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 18 19"
    >
        <defs />
        <g data-name="Group 9826">
            <g fill="currentColor" data-name="Group 9825">
                <path
                    d="M2 17a2.006 2.006 0 002 2h10a2.006 2.006 0 002-2V5H2zM4 7h10v10H4z"
                    data-name="Path 3665"
                />
                <path d="M12 2V0H6v2H0v2h18V2z" data-name="Path 3666" />
                <path d="M6 9h2v6H6z" data-name="Rectangle 10635" />
                <path d="M10 9h2v6h-2z" data-name="Rectangle 10636" />
            </g>
        </g>
    </svg>
);

export const Locked = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 15 21"
    >
        <defs />
        <g fill="currentColor" data-name="Group 9509" transform="translate(-653 -1634)">
            <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                data-name="Ellipse 648"
                transform="translate(659 1647.765)"
            />
            <path
                d="M665.857 1643.45v-4.2a5.358 5.358 0 00-10.714 0v4.2a2.123 2.123 0 00-2.143 2.1v7.35a2.124 2.124 0 002.143 2.1h10.714a2.124 2.124 0 002.143-2.1v-7.35a2.123 2.123 0 00-2.143-2.1zm-8.571-4.2a3.215 3.215 0 016.429 0v4.2h-6.429zm-2.143 13.65v-7.35h10.714v7.35z"
                data-name="Path 2948"
            />
        </g>
    </svg>
);

export const Unlocked = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 21 21"
    >
        <defs />
        <g data-name="Group 9508">
            <g fill="currentColor" data-name="Group 5792" transform="translate(6.332 9.45)">
                <path
                    d="M2.095 0a2.1 2.1 0 00-2.1 2.1v7.35a2.1 2.1 0 002.1 2.1h10.478a2.1 2.1 0 002.094-2.1V2.1A2.1 2.1 0 0012.573 0H2.095zm0 9.45V2.1h10.478v7.35z"
                    data-name="Path 2955"
                />
                <circle
                    cx="1.572"
                    cy="1.572"
                    r="1.572"
                    data-name="Ellipse 650"
                    transform="translate(5.763 4.203)"
                />
            </g>
            <g data-name="Group 5793">
                <path
                    fill="currentColor"
                    d="M10.481 9.45v-4.2a5.239 5.239 0 10-10.478 0V6.3h2.1V5.25a3.144 3.144 0 116.287 0v4.2z"
                    data-name="Path 2956"
                />
            </g>
        </g>
    </svg>
);

export const Warning = (
    width: number | undefined,
    height: number | undefined,
    className: string | undefined
) => (
    <svg
        className={className}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
    >
        <defs />
        <g data-name="Group 6024">
            <path
                fill="currentColor"
                d="M10.006 1.234a2.229 2.229 0 013.989 0l9.77 19.54A2.231 2.231 0 0121.771 24H2.23a2.229 2.229 0 01-1.994-3.226z"
                data-name="Polygon 59"
            />
            <g data-name="Group 5593">
                <g data-name="Group 4881">
                    <path
                        fill="#fff"
                        d="M10.5 8.179h3l-1.038 8.089a.472.472 0 01-.925 0z"
                        data-name="Rectangle 4770"
                    />
                </g>
                <g data-name="Group 4882">
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        fill="#fff"
                        data-name="Ellipse 428"
                        transform="translate(10.5 18.178)"
                    />
                </g>
                <g data-name="Group 4882">
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        fill="#fff"
                        data-name="Ellipse 428"
                        transform="translate(10.5 6.679)"
                    />
                </g>
            </g>
        </g>
    </svg>
);
